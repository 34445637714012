:global {
  .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }

  .smallText {
    font-family: "Roboto", sans-serif;
    line-height: 28px;
    font-weight: 300;
    letter-spacing: 0.2px;
    color: var(--alto);
    font-size: 18px;
  }

  .alignSelfEnd {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .alignBottom {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }

  .alignCenter {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 992px) {
    .alignCenterMd {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    .orderOne {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }

    .orderTwo {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }

    .hiddenSm {
      display: none;
    }

    .centerSm {
      text-align: center;
    }

    .alignSm {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: flex-end;
      -ms-flex-align: flex-end;
      align-items: flex-end;
      -webkit-box-pack: flex-end;
      -ms-flex-pack: flex-end;
      justify-content: flex-end;
    }

    .smallText {
      font-family: "Roboto", sans-serif;
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0.3px;
      color: var(--alto);
      font-size: 21px;
    }

    .bike {
      margin-top: 200px;
      height: auto;
      margin-bottom: 120px;
      position: relative;
    }

    .deskVisible {
      display: none;
    }

    .alignCenterSm {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  @media (max-width: 576px) {
    .container {
      padding-right: 20px;
      padding-left: 20px;
    }

    .smallText {
      font-size: 18px;
    }
  }

  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 96%;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1100px;
    }
  }

  .containerFluid {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
  }

  .row {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .noGutters {
    margin-right: 0;
    margin-left: 0;
  }

  .noGutters > .col,
  .noGutters > [class*="col"] {
    padding-right: 0;
    padding-left: 0;
  }

  .col1,
  .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7,
  .col8,
  .col9,
  .col10,
  .col11,
  .col12,
  .col,
  .colAuto,
  .colSm1,
  .colSm2,
  .colSm3,
  .colSm4,
  .colSm5,
  .colSm6,
  .colSm7,
  .colSm8,
  .colSm9,
  .colSm10,
  .colSm11,
  .colSm12,
  .colSm,
  .colSmAuto,
  .colMd1,
  .colMd2,
  .colMd3,
  .colMd4,
  .colMd5,
  .colMd6,
  .colMd7,
  .colMd8,
  .colMd9,
  .colMd10,
  .colMd11,
  .colMd12,
  .colMd,
  .colMdAuto,
  .colLg1,
  .colLg2,
  .colLg3,
  .colLg4,
  .colLg5,
  .colLg6,
  .colLg7,
  .colLg8,
  .colLg9,
  .colLg10,
  .colLg11,
  .colLg12,
  .colLg,
  .colLgAuto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 11px;
    padding-left: 11px;
  }

  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .colAuto {
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col1 {
    -ms-flex: 0 0 8.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col2 {
    -ms-flex: 0 0 16.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col3 {
    -ms-flex: 0 0 25%;
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col4 {
    -ms-flex: 0 0 33.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col5 {
    -ms-flex: 0 0 41.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col6 {
    -ms-flex: 0 0 50%;
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col7 {
    -ms-flex: 0 0 58.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col8 {
    -ms-flex: 0 0 66.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col9 {
    -ms-flex: 0 0 75%;
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col10 {
    -ms-flex: 0 0 83.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col11 {
    -ms-flex: 0 0 91.666667%;
    -webkit-box-flex: 0;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col12 {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .offset1 {
    margin-left: 8.333333%;
  }

  .offset2 {
    margin-left: 16.666667%;
  }

  .offset3 {
    margin-left: 25%;
  }

  .offset4 {
    margin-left: 33.333333%;
  }

  .offset5 {
    margin-left: 41.666667%;
  }

  .offset6 {
    margin-left: 50%;
  }

  .offset7 {
    margin-left: 58.333333%;
  }

  .offset8 {
    margin-left: 66.666667%;
  }

  .offset9 {
    margin-left: 75%;
  }

  .offset10 {
    margin-left: 83.333333%;
  }

  .offset11 {
    margin-left: 91.666667%;
  }

  @media (min-width: 576px) {
    .colSm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
    }

    .colSmAuto {
      -ms-flex: 0 0 auto;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    .colSm1 {
      -ms-flex: 0 0 8.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    .colSm2 {
      -ms-flex: 0 0 16.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    .colSm3 {
      -ms-flex: 0 0 25%;
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .colSm4 {
      -ms-flex: 0 0 33.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .colSm5 {
      -ms-flex: 0 0 41.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    .colSm6 {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .colSm7 {
      -ms-flex: 0 0 58.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    .colSm8 {
      -ms-flex: 0 0 66.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .colSm9 {
      -ms-flex: 0 0 75%;
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%;
    }

    .colSm10 {
      -ms-flex: 0 0 83.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    .colSm11 {
      -ms-flex: 0 0 91.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    .colSm12 {
      -ms-flex: 0 0 100%;
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .offsetSm0 {
      margin-left: 0;
    }

    .offsetSm1 {
      margin-left: 8.333333%;
    }

    .offsetSm2 {
      margin-left: 16.666667%;
    }

    .offsetSm3 {
      margin-left: 25%;
    }

    .offsetSm4 {
      margin-left: 33.333333%;
    }

    .offsetSm5 {
      margin-left: 41.666667%;
    }

    .offsetSm6 {
      margin-left: 50%;
    }

    .offsetSm7 {
      margin-left: 58.333333%;
    }

    .offsetSm8 {
      margin-left: 66.666667%;
    }

    .offsetSm9 {
      margin-left: 75%;
    }

    .offsetSm10 {
      margin-left: 83.333333%;
    }

    .offsetSm11 {
      margin-left: 91.666667%;
    }
  }

  @media (min-width: 768px) {
    .mobVisible {
      display: none !important;
    }

    .colMd {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
    }

    .colMdAuto {
      -ms-flex: 0 0 auto;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    .colMd1 {
      -ms-flex: 0 0 8.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    .colMd2 {
      -ms-flex: 0 0 16.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    .colMd3 {
      -ms-flex: 0 0 25%;
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .colMd4 {
      -ms-flex: 0 0 33.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .colMd5 {
      -ms-flex: 0 0 41.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    .colMd6 {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .colMd7 {
      -ms-flex: 0 0 58.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    .colMd8 {
      -ms-flex: 0 0 66.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .colMd9 {
      -ms-flex: 0 0 75%;
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%;
    }

    .colMd10 {
      -ms-flex: 0 0 83.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    .colMd11 {
      -ms-flex: 0 0 91.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    .colMd12 {
      -ms-flex: 0 0 100%;
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .offsetMd0 {
      margin-left: 0;
    }

    .offsetMd1 {
      margin-left: 8.333333%;
    }

    .offsetMd2 {
      margin-left: 16.666667%;
    }

    .offsetMd3 {
      margin-left: 25%;
    }

    .offsetMd4 {
      margin-left: 33.333333%;
    }

    .offsetMd5 {
      margin-left: 41.666667%;
    }

    .offsetMd6 {
      margin-left: 50%;
    }

    .offsetMd7 {
      margin-left: 58.333333%;
    }

    .offsetMd8 {
      margin-left: 66.666667%;
    }

    .offsetMd9 {
      margin-left: 75%;
    }

    .offsetMd10 {
      margin-left: 83.333333%;
    }

    .offsetMd11 {
      margin-left: 91.666667%;
    }
  }

  @media (min-width: 992px) {
    .colLg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 100%;
    }

    .colLgAuto {
      -ms-flex: 0 0 auto;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }

    .colLg1 {
      -ms-flex: 0 0 8.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }

    .colLg2 {
      -ms-flex: 0 0 16.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }

    .colLg3 {
      -ms-flex: 0 0 25%;
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .colLg4 {
      -ms-flex: 0 0 33.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .colLg5 {
      -ms-flex: 0 0 41.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }

    .colLg6 {
      -ms-flex: 0 0 50%;
      -webkit-box-flex: 0;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .colLg7 {
      -ms-flex: 0 0 58.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    .colLg8 {
      -ms-flex: 0 0 66.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }

    .colLg9 {
      -ms-flex: 0 0 75%;
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%;
    }

    .colLg10 {
      -ms-flex: 0 0 83.333333%;
      -webkit-box-flex: 0;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }

    .colLg11 {
      -ms-flex: 0 0 91.666667%;
      -webkit-box-flex: 0;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }

    .colLg12 {
      -ms-flex: 0 0 100%;
      -webkit-box-flex: 0;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .offsetLg0 {
      margin-left: 0;
    }

    .offsetLg1 {
      margin-left: 8.333333%;
    }

    .offsetLg2 {
      margin-left: 16.666667%;
    }

    .offsetLg3 {
      margin-left: 25%;
    }

    .offsetLg4 {
      margin-left: 33.333333%;
    }

    .offsetLg5 {
      margin-left: 41.666667%;
    }

    .offsetLg6 {
      margin-left: 50%;
    }

    .offsetLg7 {
      margin-left: 58.333333%;
    }

    .offsetLg8 {
      margin-left: 66.666667%;
    }

    .offsetLg9 {
      margin-left: 75%;
    }

    .offsetLg10 {
      margin-left: 83.333333%;
    }

    .offsetLg11 {
      margin-left: 91.666667%;
    }
  }
}
