@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.mobileMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  background-color: #13131d;
  width: 100%;
  height: 100vh;
  transition: all 0.4s ease-in-out;

  .closeIcon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 20px;

    .close {
      height: 29px;
      width: 29px;
    }
  }

  .mobileMenuArea {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .action {
      margin: 24px;
      cursor: pointer;
      font-size: 22px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.93;
      letter-spacing: 0.88px;
      color: #fff;
      -webkit-text-stroke: 0.4px #fff;
    }

    .active {
      color: #97f5e0;
      -webkit-text-stroke: 0.4px #97f5e0;
    }
    .green {
      padding: 16px 24px;
      border-radius: 10px;
      border: solid 1px #97f5e0;
    }
  }
}

.mobileMenuOFF {
  top: -100%;
  position: fixed;
  z-index: -10;
}

.mobileMenuON {
  position: fixed;
  top: 0;
  z-index: 100;
}

.navbar {
  padding: 30px 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  font-family: Poppins;
  transition: all 0.15s linear;
  margin-bottom: 40px;
  z-index: 11;
  @media only screen and (max-width: 1630px) {
    padding: 27px 180px;
    margin-bottom: 36px;
  }
  @media only screen and (max-width: 1500px) {
    padding: 24px 160px;
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 1300px) {
    padding: 21px 140px;
    margin-bottom: 28px;
  }
  @media only screen and (max-width: 1200px) {
    padding: 18px 20px;
    margin-bottom: 24px;

    .actionArea {
      .action {
        margin: 0 12px;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    margin-bottom: 22px;
  }
  @media only screen and (max-width: 860px) {
    padding: 15px;
    margin-bottom: 20px;
  }

  .menuBar {
    display: none;
    @media only screen and (max-width: 950px) {
      display: block;
      cursor: pointer;
    }
  }

  .actionArea {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    @media only screen and (max-width: 950px) {
      display: none;
    }
    .action {
      margin: 0 16px;
      cursor: pointer;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      letter-spacing: 0.57px;
      color: #fff;
      -webkit-text-stroke: 0.4px #fff;
      @media only screen and (max-width: 1000px) {
        margin: 0 16px;
      }
    }
    .active {
      color: #97f5e0;
      -webkit-text-stroke: 0.4px #97f5e0;
    }
    .green {
      padding: 7px 18px;
      border-radius: 9px;
      border: solid 1px #97f5e0;
    }
  }
}
.sticky {
  display: flex;
  justify-content: space-between;
  background-color: #1a1a22;
  padding: 20px 200px;
  transition: all 0.15s linear;
  @media only screen and (max-width: 1630px) {
    padding: 18px 180px;
  }
  @media only screen and (max-width: 1500px) {
    padding: 16px 160px;
  }
  @media only screen and (max-width: 1300px) {
    padding: 14px 140px;
  }
  @media only screen and (max-width: 1100px) {
    padding: 12px 120px;
  }
  @media only screen and (max-width: 900px) {
    padding: 10px 100px;
  }

  @media only screen and (max-width: 860px) {
    padding: 10px 15px;
    margin-bottom: 20px;
  }
}
