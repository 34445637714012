@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.Footer__Root {
  background-color: #111119;
  padding: 0 122px;
  font-family: Roboto;
  -webkit-text-stroke: 0.3px #d3d5e7;

  .footer__container {
    border-top: 1px solid #303045;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    .company__links {
      padding-top: 50px;
      display: grid;
      grid-template-columns: 30% 70%;

      .company_block {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo_img {
          height: 60px;
          width: 60px;
          margin-bottom: 10px;
        }
        .logo_img_text {
          height: 50px;
          width: 180px;
        }
      }

      .links_block {
        display: grid;
        grid-template-columns: repeat(3, minmax(100px, 1fr));
        padding-bottom: 40px;

        ul {
          list-style: none;
          padding-left: 0;

          li {
            margin-bottom: 16px;
            margin-top: 16px;

            a {
              font-weight: 300;
              font-size: 14px;
              line-height: 18px;
              -webkit-text-stroke: 0.3px #d3d5e7;
              color: #c9cbe1;
              text-decoration: none;
            }
          }
        }

        .link__title {
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: #ffffff;
          border-bottom: 1px solid #1a1a25;
          padding-bottom: 6px;
        }

        .marginTitle {
          margin-right: 40px;
        }
      }
    }

    .copy_rights {
      border-top: 1px solid #1a1a25;

      p {
        margin: 20px 0;
        font-weight: 300;
        text-align: center;
        -webkit-text-stroke: 0.3px #d3d5e7;
        color: #757688;
      }
    }
  }

  @media (max-width: 940px) {
    .footer__container {
      .company__links {
        grid-template-columns: 100%;
        padding-top: 40px;

        .company_block {
          margin-bottom: 30px;
        }
      }
    }
  }
  @media (max-width: 860px) {
    padding: 0 30px;
  }

  @media (max-width: 545px) {
    .footer__container {
      .company__links {
        .links_block {
          display: flex;
          flex-direction: column;
          .link__title {
            margin-top: 30px;
          }
          .marginTitle {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
