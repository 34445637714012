:global {
  html {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    min-width: 320px;
    color: var(--white);
    background: var(--newBgDark);
    overflow-x: hidden;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--neroDark) var(--dimGray);

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: var(--dimGray);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--neroDark);
    }

    &::-webkit-scrollbar-corner {
      background-color: var(--dimGray);
    }
  }

  a {
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;

    &:hover {
      text-decoration: none;
    }
  }

  a,
  button,
  input {
    font-size: 14px;
  }

  input::-webkit-search-cancel-button {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: 300;
  }

  h2 {
    font-size: 65px;
    line-height: 1.1;
  }

  h3 {
    font-size: 48px;
    line-height: 1.06;
    letter-spacing: 1px;
  }

  h4 {
    font-size: 38px;
    line-height: 1.2;
  }

  h5 {
    font-size: 15px;
    line-height: 1.8;
    letter-spacing: 2.3px;
    color: var(--nobel);
  }

  h6 {
    font-size: 17px;
    line-height: 40px;
    letter-spacing: 0.1px;
    color: var(--white);
  }

  p {
    margin: 0;
  }

  ol,
  ul,
  dl {
    margin: 0;
    padding: 0;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    html {
      overflow-x: hidden;
    }
  }
  @media (max-width: 768px) {
    h5 {
      font-size: 18px;
      line-height: 1;
      letter-spacing: 4.1px;
      color: var(--nobel);
    }

    h3 {
      font-size: 50px;
      line-height: 1;
      letter-spacing: 1px;
    }

    h2 {
      font-size: 50px;
      line-height: 1;
    }
  }

  @media (max-width: 576px) {
    h5 {
      font-size: 16px;
    }

    h3 {
      font-size: 40px;
    }

    h2 {
      font-size: 40px;
      line-height: 1;
    }
  }

  :focus {
    outline: none;
  }

  ::-moz-focus-inner {
    border: 0;
  }

  .noScroll {
    height: 100vh;
    width: 100vw;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
  }

  .noSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

:root {
  // HIGHLIGHT COLORS
  ////////////////////////////////////

  // White
  --white: #fff;
  --rgbWhite: 255, 255, 255;

  // Alchemy Purple (Buttong Bg etc..)
  --electricViolet: #6104e7;

  // Ai Purple
  --mayablue: #7a71ff;

  // Link Color
  --cornflowerBlue: #8273ff;

  // Cyan
  --iceCold: #97f5e0;

  // GRAY TONES
  ////////////////////////////////////

  // Sidepanel Bg Color
  --dimGrayDark: #161616;

  // Sidepanel Highlight Bg Color
  --dimGrayLight: #202020;

  // Sidepanel Inactive Folder Color
  // Grid Bg Color
  --dimGray: #101010;

  // Mostly frontend color
  --ultraDark: #0e0e0e;

  // Focus Bg/X btn bg
  --neroDark: #202020;

  // Sidepanel Inactive Folder Color
  // Input Inactive Underline
  --neroLight: #272727;

  // Active tab icon
  --gainsboro: #d9d9d9;

  // Sidepanel Hover Folder Color
  // Input Label Text Color (Add / Search)
  --charcoal: #4d4d4d;

  // Sidepanel Inactive Folder Text Color
  --nobel: #9c9c9c;

  //ai app colors
  --aiDimGrayDark: #1f2025;

  // Elements
  --sidePanelWidth: 260px;
  --headerHeight: 66px;
  --rightToolbar: 50px;
  --btnHeight: 60px;

  --newBgDark: #151518;
  --newFormBg: #191920;
  --grayishBlue: #292933;
  --darkGrayishBlue: #45474e;
  --blackRussian: #18181d;
  --ripTide: #90e9d5;
  --stormGrey: #77798a;
  --cloudyBlue: #c9cbe1;
}
