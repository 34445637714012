@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import "./config.scss";
@import "./_common.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--neroDark) var(--dimGray);

    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }

    &::-webkit-scrollbar-track {
      background: var(--dimGray);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--neroDark);
    }

    &::-webkit-scrollbar-corner {
      background-color: var(--dimGray);
    }
  }
}
